@use '../../../styles/scss/variables';

$strengths: ("poor": variables.$red,
    "weak": variables.$orange,
    "normal": variables.$yellow,
    "good": variables.$teal,
    "strong": variables.$green);


.strength-indicator {
    display: flex;
    padding: 5px;

    .inner {
        padding: .5rem;
        text-align: center;
        font-weight: bold;
        color: variables.$light-gray;        
        flex: 1;
        transition: background-color 300ms linear;
    }

    @each $name,
    $color in $strengths {
        &.#{$name} .inner {
            background-color: $color;
        }
    }
}