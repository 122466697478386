$inputFontFamily: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
$inputLineHeight: 30px;
$inputFontSize: 1rem;

.template-input {
    padding: 0.5rem;
    display: flex;

    &:not(.disabled) {
        background: #fff;
    }

    &.disabled {
        .input-wrapper {
            align-items: center;
        }
    }

    .input-wrapper {
        position: relative;
        display: flex;
        text-wrap: nowrap;

        &.last {
            flex: 1;

            input {
                text-align: left;
                width: auto !important;
                flex: 1;
            }
        }

        > input {
            border: none;
            outline: none;
            background: none;
            border-bottom: 3px solid #333;

            &[type="text"] {
                font-family: $inputFontFamily;
                font-size: $inputFontSize;
                line-height: $inputLineHeight;
                // text-align: center;
            }
        }
    }

    .template.edit .data{
        display:flex;
        
        .input-group{
            flex: 1;                
            margin: .1rem;
        }
    }

    .input-highlight {
        border-top: 3px solid #f39c12;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0;
        font-family: $inputFontFamily;
        font-size: $inputFontSize;
        line-height: $inputLineHeight;
        color: transparent;
        overflow: hidden;
        user-select: none;
        padding: 0 2px;
    }
}
