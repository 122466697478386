.expansion-panel {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    .top {
        padding: .5rem;
        display: flex;
        cursor: pointer;

        h4 {
            margin: 0 1rem;
            flex: 1 1;
        }
    }

    .content {
        padding: .5rem;
    }
}