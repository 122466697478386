@use "../../../../styles/scss/variables" as *;

.switch-group {
    display: flex;
    justify-content: space-between;
    margin: .5rem 0;

    .switch-label {
        margin-right: .5rem;
        text-wrap: nowrap;
    }

    &.collapsed {
        border: 2px solid;
        border-radius: $border-radius;
        margin: .5rem .25rem;
        padding: .1rem .25rem;

        .switch-label {
            margin: 0;
        }

        &.value__false {
            border-color: var(--red-600);
            background-color: var(--red-200);
        }

        &.value__true {
            border-color: var(--teal-600);
            background-color: var(--teal-200);
        }
    }
}
