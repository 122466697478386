@use '../../../styles/scss/variables';

.timer-component {
    // @include rainbow-bg(30s);
    
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .duration{
        font-size: 3rem;
        font-weight: 300; 
        margin-right: 2rem;       
    }

    .action-button{
        padding: .5rem;
        border-radius: 110px;
        background-color: variables.$gray-200;
        transition: background-color linear .08s;

        &:hover{
            background-color: variables.$gray-500;
        }
    }
}