.notification-host {
    .notification-wrapper {
        position: relative;

        .notification-progress {
            position: absolute;
            height: 6px;
            background: rgb(100, 100, 100);
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 0px 0px 16px 16px;
            mix-blend-mode: color-burn;
            transition: right 0.25s ease;
        }
    }
}
