.loading-container,
.loading-indicator-container {
    position: relative;
    height: 120px;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    & .inner {
        width: 80%;
        max-width: 30rem;
        flex: 1;
    }
}

.loading-bar {
    flex: 1;
    width: 100%;

    & .inner {
        display: flex;
        flex: 1;

        .bar-items {
            display: flex;
            flex: 1;
            position: relative;
            overflow: hidden;
            height: 1rem;

            & > div {
                height: 1rem;
                border-radius: 20rem;
                transform-origin: 0% 50%;
                position: absolute;                
                animation-duration: 3s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;                
            }

            & > div.item__1 {
                background-color: #0091e3;
                width: 25%;
                z-index: 100;
                animation-name: indeterminateAnimation;
            }
            & > div.item__2 {
                background-color: #6bb7ff;
                width: 15%;
                z-index: 99;
                margin-left: -2rem;
                animation-name: indeterminateAnimation2;
            }
            & > div.item__3 {
                background-color: #d1eeff;
                width: 5%;
                z-index: 98;
                margin-left: -2rem;
                animation-name: indeterminateAnimation3;
            }
        }
    }
}

@keyframes indeterminateAnimation {
    0% {
        transform: scaleX(0.4);
        left: -20%;
    }
    75% {
        transform: scaleX(1);
        left: 100%;
    }
    100% {
        transform: scaleX(1);
        left: 100%;
    }
}

@keyframes indeterminateAnimation2 {
    0% {
        transform: scaleX(0.4);
        left: -10%;
    }
    75% {
        transform: scaleX(1);
        left: 125%;
    }
    100% {
        transform: scaleX(1);
        left: 125%;
    }
}

@keyframes indeterminateAnimation3 {
    0% {
        transform: scaleX(0.4);
        left: -5%;
    }
    75% {
        transform: scaleX(1);
        left: 140%;
    }
    100% {
        transform: scaleX(1);
        left: 140%;
    }
}
