.sync-protocols-list {
    .truncate-json {
        width: 150px;
    }
}

.dev-ops-sync__protocols-code-view {
    max-width: 70vw;    

    >pre{
        max-height: 80vh;        
    }
}
