@use '../../../../styles/scss/variables';

@mixin jobTaskProperties {

    &.is-accountable:not(.k-selected) {
        background-color: transparentize(variables.$darkPrimary, .8);
    }

    &.is-executive:not(.k-selected) {
        background-color: transparentize(variables.$accent2, .8);
    }

    .is-overdue-icon svg {
        fill: var(--bs-danger);
        position: relative;
        top: -.15rem;

    }

    &.k-selected .is-overdue-icon svg {
        fill: var(--bs-light);
    }
}

.time-entity-input {
    .row {
        margin-bottom: .5rem;        
        margin-left: 0;

        &.dates {
            display: flex;
            flex-direction: row;            

            span {
                font-weight: bold;
                // width: auto;
            }

            .time-part {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: .5rem 0;

                .action-btn {
                    margin: 0 .5rem;                
                }

                .duration-switch{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }

            .time-input {
                width: 6rem;
            }

            .time-input,
            .amount-input,
            .caption {
                margin: 0 .25rem;

                &:first-of-type {
                    margin-left: 0;
                }

                input {
                    text-align: center;
                }
            }
        }


        &.job-data {
            >span {
                margin: .25rem 0;
            }
        }

        .switch-control {
            &:first-of-type {
                margin-left: 0;
            }

            margin: 0 1rem;
        }

        &.action {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            div {
                display: flex;
                flex: 1;

                &.right {
                    justify-content: flex-end;
                }
            }
        }
    }
}

.combo-box-item {

    display: flex;
    font-size: .8rem;

    &.highlighted {

        .name,
        .designation,
        .description {
            font-weight: 800;
        }
    }

    .name:not(.no-highlight),
    .designation:not(.no-highlight),
    .billToName:not(.no-highlight) {
        font-weight: 600;
    }

    span:not(:last-of-type) {
        margin-right: .5rem;
    }
}

// .k-list-ul {
//     counter-reset: totals;
// }

.k-list-item.job-task {


    &.Begin_x002D_Total,
    &.End_x002D_Total {

        &,
        & * {

            font-weight: 800;
            pointer-events: none;
            cursor: not-allowed;
        }

    }

    // &>div::before {
    //     content: counter(totals);
    // }

    // &.Begin-Total{
    //     counter-increment: totals;
    // }

    // &.End-Total {
    //     counter-increment: totals -1;
    // }

    &.Heading {

        &,
        & * {
            font-weight: 700;
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    @include jobTaskProperties();
}