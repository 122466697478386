@use '../../../../../styles/scss/variables';
@import '../TimeEntityInput.scss';

.combo-box-footer {
    padding: .5rem;
    border-top: 1px solid variables.$text;
    margin-top: 1rem;
    background: variables.$light-gray;

    h1,
    h2,
    h3 {
        font-size: 1rem;
        font-weight: 400;
        padding: 0;
        margin: 0;
    }

    .k-tooltip-content & {
        background: #000;
        border: none;
        margin: 0;
    }

    div {
        @include jobTaskProperties();
        padding: .1rem;
    }
}

.job-details {
    h1 {
        font-weight: 700;
    }

    h2:first-of-type {
        font-weight: 600;
    }

    h3 {
        font-style: italic;
    }
}