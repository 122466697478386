.service-provider-selection {
    // max-width: ;

    .inner {
        display: flex;

        > span {
            flex: 1;
            margin-right: 0.5rem;
            min-width: 0;
        }

        div {
            flex: 0;
        }
    }

    .icon-btn {
        margin: 0.25rem;
    }
}

.service-provider-selection-item {
    padding: 0.25rem;
    width: 100%;

    display: flex;

    .icon {
        flex: 0;
        display:flex;
        align-items: center;
        justify-content: center;        

        & > * {
            width: 2rem;
            height: 2rem;
        }
    }

    .data {
        flex: 1;
        padding-left: .5rem;
    }

    .service-provider {
        font-weight: 600;
    }

    .service-connection {
        padding-left: 0.25rem;

        div:nth-of-type(2) {
            font-style: italic;
        }
    }
}
