.job-data .job-task-details {
    transition: none;
    will-change: max-height;
    overflow: hidden;
}

.job-data .job-task-details.visible {
    transition: max-height 1s ease-out, opacity 0.5s ease-out;
    will-change: max-height;
}
