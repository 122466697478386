.dashboard-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .body {
        width: 100%;

        > * {
            margin: 1.5rem;
        }
    }

    .title {
        font-weight: 600;

        &:not(.expansion-panel .title) {
            margin-top: 1rem;
            margin-left: 1rem;
        }
    }
}
