@use "variables";

.no-spin::-webkit-inner-spin-button {
    display: none;
}

/* Telerik Styles */

.k-switch-on .k-switch-thumb {
    background: white;
}

.k-switch-xs {
    .k-switch-sm {
        width: 36px;
        height: 20.5px;

        .k-switch-track {
            width: 36px;
            height: 19.5px;
        }

        .k-switch-thumb {
            width: 15px;
            height: 15px;
        }

        &.k-switch-off .k-switch-thumb-wrap {
            left: 9px;
        }

        &.k-switch-on .k-switch-thumb-wrap {
            left: calc(100% - 9px);
        }
    }
}

.k-rounded-md {
    border-radius: 0.5rem !important; //default .25rem;
}

.k-notification {
    // Should already be flex according to Telerik docs but is block
    display: flex !important;
}

.btn {
    border-radius: 0.5rem;
}

.k-step-list-horizontal {
    .k-step-link {
        max-width: 14em;
    }
}

.k-stepper .k-step-label:only-child {
    background-color: variables.$app-background;
}

.switch-align-label {
    .inner-wrap {
        display: flex;
        align-items: center;

        .switch-label {
            position: relative;
            top: -0.1rem;
        }
    }
}

.k-animation-container {
    // Stack Popups higher than Dialogs
    // Apparently fixed in React 4.13.0 where z-index should be calculated automatically
    // https://www.telerik.com/kendo-react-ui/components/popup/stack-order/
    z-index: 10003 !important;
}

// Dialog styles

.k-dialog-title {
    // color: $primary;
    font-weight: bold;
    justify-content: center;
    font-size: 1.5rem;
}

// Custom Styles for Tabbed Content to adjust size based on flex

.k-tabstrip.full-size {
    padding: 0;

    &.k-tabstrip-top {
        > .k-tabstrip-items {
            border: none;

            > .k-item {
                border-radius: 0px;
                margin-bottom: 0px;
            }
        }

        > .k-content {
            border-radius: 0px;
            border-width: 0px;
        }
    }

    > .k-tabstrip-items {
        border-color: #fff;
        background-color: rgb(7, 120, 242);
        padding-left: variables.$spacer * 0.5;
        padding-right: variables.$spacer * 0.5;

        > .k-item {
            border-width: 4px 0px 0px 0px;
            border-color: transparent;
            color: #fff;

            &:hover {
                border-top-color: #fff;
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0.25),
                    transparent
                );
                color: #fff;
            }

            &.k-state-active {
                // background-color: rgb(7, 120, 242);
                border-top-color: #fff;
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0.25),
                    transparent
                );
                color: #fff;
            }
        }
    }

    > .k-content {
        display: flex;
        flex: 1;
        padding: 0;
        background-color: #f2f6fa;

        > .k-animation-container {
            display: flex;
            flex: 1;

            > .k-child-animation-container {
                display: flex;
                flex: 1;

                > [role="tabpanel"] {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                }
            }
        }
    }
}

.k-combobox {
    flex: 1;
}

span.k-input.k-datepicker {
    display: flex;

    > .k-dateinput {
        flex: 1;
    }
}

// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//     .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
//     .border#{$infix}-right {    border-right: $border-width solid $border-color !important; }
//     .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
//     .border#{$infix}-left {     border-left: $border-width solid $border-color !important; }

//     .border#{$infix}-top-0 {    border-top: 0 !important; }
//     .border#{$infix}-right-0 {  border-right: 0 !important; }
//     .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
//     .border#{$infix}-left-0 {   border-left: 0 !important; }

//     .border#{$infix}-x {
//       border-left: $border-width solid $border-color !important;
//       border-right: $border-width solid $border-color !important;
//     }

//     .border#{$infix}-y {
//       border-top: $border-width solid $border-color !important;
//       border-bottom: $border-width solid $border-color !important;
//     }
//   }
// }
