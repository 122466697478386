.template-list{
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;

    padding-bottom: 2rem;

    .api-grid{
        flex: 1;        
        min-height: 0;
        display: flex;
        flex-direction: column;
    
      
        }
}