.cell.cell__template-input {
    .template-input {
        max-width: 100%;
        overflow-x: auto;
        
        .input-wrapper {
            input,
            input[type="text"],
            span.input-highlight {
                font-size: 0.8rem !important;
            }
        }
        
        // .btn__addTemplate{
        //     position: absolute;
        //     right: .2rem;
        // }
        .template {
            font-size: 0.8rem;

            img[width="32"] {
                width: 20px;
                height: 20px;
            }

            >div div{
                padding: .1rem;
            }

            .btn__delete{
                margin-left: .2rem;
            }
        }

    }
}
