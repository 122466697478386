.budget-widget {
    .quick-overview {
        display: flex;
        flex-direction: column;

        > div {
            display: flex;
            align-items: center;
        }
    }
}

.color-swatch {
    width: 1rem;
    height: 1rem;
    display: block !important;
    margin-right: 0.5rem;
}
