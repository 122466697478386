.k-dateinput {

    .k-dateinput-wrap {
        height: 100%;
        align-items: center;
        justify-content: center;

        input {            
            flex: 1;
            text-align: center;
        }

        // &:focus, &:active{
        //     span{
        //         display: none;
        //     }
        //     input{
        //         display: block;
        //     }
        // }
    }

}