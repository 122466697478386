.app-dialog {
    &.notice {
      .k-dialog-titlebar {
        background-color: #3498db;
        color: white;
      }
    }
  
    &.confirm {
      .k-dialog-titlebar {
        background-color: #2ecc71;
        color: white;
      }
    }
  
    &.important {
      .k-dialog-titlebar {
        background-color: #e74c3c;
        color: white;
      }
    }
  
    .dialog-content {
      padding: 20px;
      font-size: 16px;
      line-height: 1.5;
    }
  
    .dialog-actions {
      display: flex;
      justify-content: flex-end;
      padding: 15px;
  
      .dialog-button {
        margin-left: 10px;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s ease;
  
        &.primary-action {
          background-color: #3498db;
          color: white;
  
          &:hover {
            background-color: darken(#3498db, 10%);
          }
        }
  
        &.secondary-action {
          background-color: #ecf0f1;
          color: #34495e;
  
          &:hover {
            background-color: darken(#ecf0f1, 10%);
          }
        }
      }
    }
  }