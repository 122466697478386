@use '../../styles/scss/variables';

$darkPrimary: darken($color: variables.$primary, $amount: 15);

.app-error {
    text-align: center;

    &.fullscreen {
        background: variables.$primary;
        margin: 0;
    }

    h1 {
        font-weight: bold;
        font-size: 1.4rem;
    }

    .inner {
        background: variables.$light;
    }

    .control-wrap>.inner-wrap.fill {
        border: 4px solid variables.$darkPrimary;
    }

    .img-wrapper svg {
        width: 100%;
        height: 100%;
        max-height: 50vh;
    }

    button.btn {
        font-size: 1.7rem;
        padding: 1rem;
        font-weight: 700;

        &,
        &:hover,
        &:active {
            color: variables.$darkPrimary;
        }

        background: white;

    }
}