@use "../../../../styles/scss/variables" as *;
@use "sass:color";

$action-column-width: 2.75rem;

.plm-job-list {
    display: flex;
    flex-direction: column;

    border: 1px solid $app-border;

    .headers {
        display: flex;

        width: 100%;

        > .col {
            &,
            &.col__action {
                background: $light-gray;
            }
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
            padding: 0.5rem;
        }
    }

    & > .item-row:last-of-type {
        border-bottom: none;
    }

    .item-row {
        display: flex;
        width: 100%;
        position: relative;

        &:not(.headers__empty) {
            border-bottom: 1px solid $app-border;
        }

        &.has-action {
            padding-right: $action-column-width;
        }

        & > .col:last-child {
            border-right: none;
        }

        &:not(.headers):nth-of-type(odd) {
            & > .col,
            & > .col__action {
                background-color: $app-background-shadow;
            }
        }

        &:not(.headers):not(.in-edit):not(.selected):hover {
            & > .col,
            & > .col__action {
                background-color: $app-hover;
            }
        }

        &:not(.headers).selected {
            & > .col,
            & > .col__action {
                background-color: color.scale(
                    $primary,
                    $lightness: 70%
                ); // $primary;
            }
        }

        @for $i from 1 through 5 {
            &.headers.level-#{$i} {
                margin-left: $i * 3rem;
                width: calc(100% - ($i * 3rem));
            }

            &:not(.headers).level-#{$i} {
                margin-left: ($i - 1) * 3rem;
                width: calc(100% - (($i - 1) * 3rem));
            }
        }

        // Highlight Job Rows
        &.job-row > .col {
            background-color: var(--orange-100) !important;
        }
    }

    .col {
        border-right: 1px solid $app-border;
        overflow-x: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        &.col__attachments {
            span:not(.disabled) {
                text-decoration: underline;
                cursor: pointer;
                color: $primary;
            }
        }

        .template-input {
            flex: 1;
            max-width: 100%;

            .input-wrapper {
                input,
                input[type="text"],
                span.input-highlight {
                    font-size: 0.8rem !important;
                }
            }

            .template {
                font-size: 0.8rem;

                img[width="32"] {
                    width: 20px;
                    height: 20px;
                }

                > div div {
                    padding: 0.1rem;
                }

                .btn__delete {
                    margin-left: 0.2rem;
                }
            }
        }

        &.col__action {
            position: absolute;
            right: 0;
            width: $action-column-width;
            height: 100%;
            padding: 0.5rem;
            background: $app-background;
            box-shadow: -10px 0px 8px -5px rgba(0, 0, 0, 0.25);
            overflow: hidden;
            transition: width 0.1s ease-in;
            display: flex;
            align-items: center;

            & > .placeholders {
                display: block;
                text-align: center;
                font-size: 4rem;
                color: gray;
                line-height: 1.3rem;
                position: relative;
                top: -0.4rem;
            }

            & > .actions {
                display: none;
                flex: 1;
                flex-wrap: nowrap;
            }

            &:hover {
                &:not(.header) {
                    width: calc(($action-column-width * 2) - 0.5rem);
                }

                & > .placeholders {
                    display: none;
                }

                & > .actions {
                    display: flex;
                }
            }
        }

        & > .control-wrap:not(.switch-control) {
            flex: 1;
            padding: 0 0.5rem;
        }

        &.col__selection {
            flex: 0;
            min-width: $action-column-width;
        }

        .col__selection.read-only {
            width: 100%;
            padding: 0 0.5rem;
        }
    }

    .plm-job-type-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .plm-job-type-item {
            flex-wrap: wrap;
            align-items: center;
            width: 100%;

            &.item__empty {
                padding: 0.5rem 0;
            }
        }

        .row__posAdj {
            background-color: var(--green-100);
        }

        .row__negAdj {
            background-color: var(--red-100);
        }
    }

    .plm-item-wrapper {
        width: 100%;
        max-width: 100%;
    }

    .unsaved {
        border: 3px solid var(--orange-200);
    }

    .in.edit {
        border: 3px solid var(--blue-200);
    }
}

// Attachment Dialog

.file-attachment-link {
    text-decoration: underline;
    cursor: pointer;
    color: $primary;
}
