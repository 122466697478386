@use "../../../../styles/scss/variables";

@mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key(variables.$grid-breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get(variables.$grid-breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

.control-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &[class*="col-"] {
        padding-left: 5px;
        padding-right: 5px;
    }

    &.centered {
        > .inner-wrap {
            margin-left: auto;
            margin-right: auto;
        }
    }

    > .control-wrap-caption {
        padding-left: 0.2rem;
        font-size: 0.8rem;
    }

    > .inner-wrap {
        display: flex;
        position: relative;

        // Extra div when using tooltips
        > div {
            flex: 1;
        }

        .suffix {
            background: variables.$kendo-button-bg;
            display: flex;
            align-items: center;
            padding: 0.25rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.has-suffix {
            display: flex;
            align-items: stretch;
            background: variables.$kendo-button-bg !important;

            & > *:first-child {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }

        &.fill {
            background-color: transparent;
            border: 1px solid #ced4da;
            border-radius: variables.$border-radius;

            @include respond-above(xs) {
                & {
                    border-radius: variables.$border-radius-sm;
                }
            }

            @include respond-above(md) {
                & {
                    border-radius: variables.$border-radius-lg;
                }
            }
        }

        &.error {
            .k-textbox {
                cursor: help;
            }
        }

        &.disabled {
            opacity: 0.65;
            filter: grayscale(0.1);

            &.fill {
                background-color: #dddddd;
            }
        }

        &.fill.error {
            border-color: variables.$danger;
        }

        > .k-datepicker {
            flex: 1 1;
            width: 0;

            > .k-picker-wrap {
                border-style: none;
            }
        }

        // Allow shrink/grow and remove borders
        > .k-textbox,
        > .k-datepicker
            > .k-picker-wrap
            > .k-dateinput
            > .k-dateinput-wrap
            > .k-textbox {
            flex: 1 1;
            width: 0;
            border-style: none;
        }

        > .k-autocomplete {
            flex: 1 1;
            width: 0;
            border-style: none;
        }

        > .k-textbox-container {
            display: inline-flex;
            flex: 1 1;
            width: 0;

            > .k-textbox {
                min-width: 0;
                border-style: none;
            }
        }

        > .k-button {
            &,
            &.k-rounded-md {
                border-radius: calc(variables.$border-radius - 0.2rem) !important;
            }
        }

        &.multiple {
            > :first-child,
            > :first-child > .k-textbox {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            > :last-child,
            > :last-child > .k-textbox {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        > .k-textbox + .k-select,
        .k-textbox-container + .k-select,
        .k-autocomplete + .k-select {
            border-radius: 0 2px 2px 0;
            padding: 0;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            text-align: center;
            padding: 0;
            border-width: 0;
            cursor: pointer;
            border-color: variables.$input-border-color;
            // color: $button-text;  // TODO Fix empty variable
            color: black;
            // background-color: $button-bg; // TODO Fix empty variable
            // background-color: white;
            padding-top: variables.$input-padding-y;
            padding-right: variables.$input-padding-y;
            padding-bottom: variables.$input-padding-y;
            padding-left: variables.$input-padding-y;

            &:hover {
                border-color: variables.$input-border-color;
                // color: $button-hovered-text;
                // background-color: $button-hovered-bg;
            }

            //TODO: fix this reference
            $button-pressed-text: black;
            $button-pressed-bg: lightgray;

            &:focus {
                border-color: variables.$input-border-color;
                color: $button-pressed-text;
                background-color: $button-pressed-bg;
            }
        }

        > .k-textbox-container + .k-select {
            padding-top: 1.125em;
        }

        > .k-dropdown {
            display: inline-flex;
            flex: 1 1;
            width: 0;

            > .k-dropdown-wrap {
                border: none;
            }
        }

        > .btn {
            border: none;
        }
    }
}
