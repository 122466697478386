@use "../../../styles/scss/variables";

.item-render.item-render__work-item {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .data__System_State {
        &.state-new {
            color: variables.$blue;
        }
        
        &.state-active {
            color: variables.$warning;
        }
        
        &.state-resolved {
            color: variables.$blue;
        }
        
        &.state-resolved,
        &.state-removed,
        &.state-closed {
            color: variables.$success;
        }
    }
}
