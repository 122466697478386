@use '../../../styles/scss/variables';

.popup-content.k-popup {
  border: none;
  padding: 0 !important;
}

.app-header {
  padding-top: 1.5rem;
  position: sticky;
  top: 0;
  background: variables.$app-background;
  z-index: 100;

  .navbar-nav {
    flex: 1;
    justify-content: stretch;
    padding: 0 1.5rem;
  }
}

.find-all-box-item {
  flex: 1;
  margin-right: 2rem;

  button {
    display: none;
  }
}

.nav-link {
  color: variables.$text !important;
}

.profile-popup {
  background: variables.$light-gray;

  h2,
  h3 {
    font-weight: 600;
  }

  h3 {
    font-size: 1.25rem;
  }

  header {
    padding: 1rem;
    background: darken($color: variables.$primary, $amount: 15);
    color: white;


    .user-data {
      display: flex;

      img {
        height: 64px;
        width: 64px;
        margin-right: 1rem;
      }


      h2,
      p {
        margin-bottom: .5rem;
      }
    }
  }

  .content {
    padding: .5rem;

  }

}

.breadcrumb {
  margin: 0 1.5rem;
  background-color: transparent !important;

  .breadcrumb-item a {
    color: variables.$text;
    font-size: 1.1rem;
    font-weight: 600;

    &:not(:hover) {
      text-decoration: none;
    }
  }
}