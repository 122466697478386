.back-btn {
    padding: 0;
    position: relative;
    left: -.5rem;
    cursor: pointer;    

    i {
        position: relative;
        top: -.1rem;
    }

    &:hover {
        span {
            text-decoration: underline;
        }
    }
}