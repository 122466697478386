@use '../../../styles/scss/variables';

span.template {
    display: inline-block;
    user-select: none;
    position: relative;
    display: flex;

    border-width: 2px;
    border-style: solid;
    background-color: #eee;

    &.edit {
        background-color: #fff;
        border-width: 2px;
    }

    .data {
        position: relative;

        *:not(.bg-overlay){
            z-index: 10;
        }
        .bg-overlay {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-color: white;
            opacity: 70%;            
        }

        input{
            min-width: 5rem;
        }
    }

    > div {
        display: flex;
        flex-direction: row;

        div {
            padding: 0.25rem 0.25rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            &.data {
                padding-left: 0.5rem;
            }
        }

        .form-check-input {
            margin: 0 0.5rem;
        }

        .data__default:nth-child(3) {
            margin-left: 0.5rem;
            padding-left: 0.5rem !important;
            border-left: 2px solid variables.$dark
        }

        .btn__delete,
        .btn__save {
            margin-left: 0.5rem;
            cursor: pointer;
        }
    }

    .menu__type-menu {
        border: 2px solid #000;
        border-top: none;
        display: flex;
        background-color: white;
        flex-direction: column;
        position: absolute;
        left: -2px;
    }
}
