@use '../../styles/scss/variables';

.report-item {
    cursor: pointer;
    padding-top: 1rem;
    
    &:last-of-type{
        padding-bottom: .25rem;
    }

    &:hover {
        background-color: variables.$app-hover;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid variables.$app-border;
    }
    
    .expansion-panel {
        margin-top: 0rem;
    }
}