@use "../../../../styles/scss/variables";

.entities-list {
    .day-container {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        cursor: pointer;

        &:not(:last-of-type) {
            border-bottom: 1px solid variables.$app-border;
        }

        .title {
            display: flex;
            flex-direction: column;
            background-color: darken($color: variables.$app-background, $amount: 5);

            &:hover {
                background-color: variables.$app-hover;
            }

            .top {
                padding: 1rem 0.5rem;
                display: flex;

                h4 {
                    margin: 0 1rem;
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                }

                .right {
                    display: flex;
                    min-width: 18rem;
                    justify-content: space-between;

                    .actions-part {
                        margin-right: 2rem;
                        height: 1.5rem;
                        display: flex;

                        .actions,
                        .action-placeholders {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }

            .time-bars {
                display: flex;
                flex-direction: column;
                flex: 1;

                .time-bar {
                    background-color: variables.$primary;
                    min-height: 3px;
                    flex: 1 0;
                }

                &:hover {
                    .timeline {
                        display: block;
                    }
                }
            }

            .timeline {
                display: none;

                .captions {
                    display: flex;
                    justify-content: space-between;
                    text-align: center;
                }

                .markers {
                    display: flex;
                    justify-content: space-between;

                    .marker {
                        background-color: gray;
                        min-width: 2px;
                        min-height: 3px;
                        position: relative;
                        margin-top: 2rem;

                        .caption {
                            position: absolute;
                            left: max(-1.3vw, -1.1rem);
                            font-size: min(1.2vw, 1rem);
                            top: max(-1.5rem, -2vw);
                            color: gray;
                        }
                    }
                }

                span {
                    display: inline-block;
                }
            }
        }

        .items-list {
            .time-entity {
                padding: .5rem 2rem;
                display: flex;
                flex-direction: column;

                &:nth-child(2n){
                    background-color: variables.$app-background-shadow;
                }

                .inner {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0.25rem 0;
                }

                &:first-of-type {
                    border-top: 1px solid variables.$app-border;
                }

                &:not(:last-of-type) {
                    border-bottom: 1px solid variables.$app-border;
                }

                &:hover {
                    background-color: variables.$app-hover;
                }

                .left {
                    display: flex;
                    flex-direction: column;
                    width: 40%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    .data__description1 {
                        font-weight: 600;
                        font-size: 1.2rem;
                    }

                    .data_description2{
                        margin-left: .5rem;
                    }
                }

                .center {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                }

                .icon__isEditable {
                    fill: variables.$primary;
                }

                .data span {
                    &:not(:first-of-type) {
                        margin-left: 1rem;
                    }
                }
            }
        }
    }
}
