@use "../../../styles/scss/variables";

.time-entities {
    h2 {
        margin-left: 1.5rem;
        font-size: 1.5rem;
    }

    & > .top {
        display: flex;

        .left {
            flex: 1;
        }
    }

    .filter-area {
        row-gap: 1rem;
    }

    .filter__isEditable {
        max-width: 6rem;

        .icon__isEditable {
            fill: variables.$primary;
        }
    }

    .time-entity-actions {        
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > div > * {
            margin: 0.75rem 0;
        }
    }

    .divider--editor-divider {
        width: 100%;
        height: 0;
        display: flex;
        justify-content: center;
        position: relative;
        cursor: pointer;

        &.collapse-editor {
            top: -2rem;

            &:hover {
                animation: collapse-up 2s cubic-bezier(0.65, 0.05, 0.36, 1)
                    infinite;
            }
        }

        &.expand-editor {
            top: -2.3rem;

            &:hover {
                animation: expand-down 2s cubic-bezier(0.65, 0.05, 0.36, 1)
                    infinite;
            }
        }

        svg {
            background: variables.$app-background;
        }

        &.expand-editor svg {
            transform: rotate(180deg);
        }
    }

    @keyframes collapse-up {
        0% {
            top: -2rem;
        }

        10% {
            top: -2.3rem;
        }

        45% {
            top: -2rem;
        }

        100% {
            top: -2rem;
        }
    }

    @keyframes expand-down {
        0% {
            top: -2.3rem;
        }

        10% {
            top: -2rem;
        }

        45% {
            top: -2.3rem;
        }

        100% {
            top: -2.3rem;
        }
    }
}
