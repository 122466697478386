@use '../../styles/scss/variables';

div.report-template-item {

    border: 1px solid variables.$app-border;
    background-color: white;
    display: inline-block;
    padding: 1rem;
    margin: 1rem;
    border-radius: variables.$border-radius;
    cursor: default;
    user-select: none;

    &:hover {
        background-color: variables.$app-hover;
    }

    &.selected {
        background-color: variables.$primary;
    }

    .name {
        font-weight: 700;
    }
}

.report-option {
    margin: .5rem;
}

.report-wizard {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    &>.left {
        border-right: 1px solid variables.$app-border;
    }

    &>div>.title {
        margin: .5rem;
        font-weight: 600;
    }

    .report-wizard-inner {
        height: 100%;
        overflow: auto;
    }

    .stepper-control {
        position: sticky;
        bottom: 0;
        padding-top: 1rem;
        padding-bottom: 2.5rem;
        background: variables.$app-background;
    }

    .data-filters {
        align-items: center;

        &>* {
            margin-top: .5rem;
        }
    }

    nav {
        margin-bottom: 1rem;
    }
}

.step-2 {
    display: flex;
    flex-direction: column;
}

.step-3 {
    display: flex;
    flex-direction: column;
    align-items: center;

    .tools {
        width: 100%;
        position: sticky;
        top: 0;
        background: variables.$app-background;
        z-index: 100;
        justify-content: space-around;
    }

    .report-wrapper {
        max-width: 80%;
        display: flex;
        box-shadow: 0px 5px 20px 1px darkgray;
        margin-top: 2rem;

        &>div {
            display: flex;
            min-width: 0;
        }
    }
}