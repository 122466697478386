.api-grid {
    position: relative;

    .grid-toolbar {
        display: flex;
        margin-bottom: 1rem;

        > *:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .loading-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($color: #000000, $alpha: 0.1);
    }
}
