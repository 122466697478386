.time-tracking-session {
    border-style: solid;
    border-width: 3px 3px 3px 10px;
    border-radius: .25rem;
    margin: 1.5rem;
    padding: 1rem;

    h3 {
        font-size: 1.2rem;
        font-weight: 700;
    }

    .title-editor {
        display: flex;
        align-items: center;
        flex-direction: column;

        >* {
            margin: .5rem 1rem;
        }
    }

    .overview {
        display: flex;

        .timer {
            flex: 0;
        }
    }
}