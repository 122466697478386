@use "../../../styles/scss/variables";

aside.side-nav {
    &.expanded {
        background-color: variables.$app-background;
        z-index: 500;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.25);
    }
}

.side-nav-wrapper {
    nav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        padding-bottom: 2.5rem;

        &:not(.collapsed) {
            width: 100%;
        }

        header {
            display: flex;
            padding: 1rem;
            align-items: center;
            margin-bottom: 2rem;
            flex-wrap: wrap;
            cursor: pointer;
            margin-top: 1rem;

            * {
                flex: 1;
            }

            svg {
                max-width: 100px;
                min-width: 60px;
                margin-right: 1rem;
            }

            h1 {
                font-size: 1.2rem;
                font-weight: 700;
                position: relative;
                top: 0.45rem;
            }
        }

        &.collapsed {
            max-width: 100%;

            header {
                // padding: 0.5rem;

                svg {
                    min-width: 0;
                    max-width: none;
                    width: 2rem;
                    margin: 0;
                }
            }
        }
    }

    .sidenav {
        flex: 1;

        > .nav-item {
            > .nav-link {
                color: variables.$gray-300;
                text-rendering: geometricPrecision;
                transform: translateZ(0);
                // border-top-left-radius: 2px;
                // border-bottom-left-radius: 2px;

                a {
                    outline: none;
                }
            }
        }
    }

    .footer-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        padding: 0 1rem;
        height: 5rem;
        border-top: 1px solid variables.$app-border;

        & > div:last-child {
            cursor: pointer;
        }
    }
}
