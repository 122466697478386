@use './../../styles/scss/variables';

.login-container.row {
    background: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;

    & > .inner {
        width: 95%;

        @media screen and (min-width: 1350px) {
            width: 80%
        }

        @media screen and (min-width: 1800px) {
            width: 60%;
        }
    }

    h1 {
        color: variables.$text;
        text-align: left;
        font-weight: 200;
        margin-left: 1rem;

        span {
            font-weight: 700;
        }
    }

    .subtitle{
        color: variables.$text;
        text-align: left;
        font-weight: 700;
        font-size: 1.1rem;
        margin-left: 1rem;
    }

    input,
    .k-input,
    button,
    .k-dropdownlist {
        font-size: 1.5rem;
    }


    input,
    .k-input {
        height: 4rem;
        padding-top: .6rem;
    }

    .control-wrap {
        margin: .5rem 0;
    }

    .login-btn,
    .submit-btn {
        &>.inner-wrap.fill {
            border: 4px solid variables.$darkPrimary;
        }
    }

    button.btn, button.k-button {
        padding: 1rem;
        font-weight: 700;

        &,
        &:hover,
        &:active {
            color: variables.$darkPrimary;
        }

        background: white;
    }

    .k-dropdown {
        & .k-dropdown-wrap {
            background-color: variables.$darkPrimary;
        }
    }

    .login-message {
        font-size: 1.3rem;
    }

    .app-version {
        position: absolute;
        left: 2rem;
        bottom: 2rem;
    }
}