.item-render {

    display: flex;
    font-size: .8rem;

    &.highlighted {

        .data__name,
        .data__designation,
        .data__description {
            font-weight: 800;
        }
    }

    .data__name:not(.no-highlight),
    .data__designation:not(.no-highlight),
    .data__billToName:not(.no-highlight) {
        font-weight: 600;
    }

    span.data:not(:last-of-type) {
        margin-right: .5rem;
    }
}