.switch-control {
    &.control-wrap>.inner-wrap.fill {
        border: none;
    }

    .switch-label {
        position: relative;
        top: .1rem;
        margin-left: .5rem;
    }
}