@use "../../../styles/scss/variables" as *;

.icon-btn {
    display: inline-block;
    margin: 0.5rem;
    cursor: pointer;

    &.disabled {
        & i,
        & svg {
            fill: gray !important;
        }
    }

    &:not(.disabled):hover {
        & i,
        & svg {
            fill: lighten($color: $text, $amount: 20);
        }
    }

    &.placeholder-button {
        position: relative;

        &:not(:hover) i {
            visibility: hidden;
        }

        &:hover .button-placeholder {
            display: none;
        }
    }

    .button-placeholder {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: $gray-500;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -3px;
    }
}

.placeholder-button-group {
    &:hover .icon-btn.placeholder-button i {
        visibility: visible;
    }

    &:hover .button-placeholder {
        display: none;
    }
}

button.icon-btn {
    background: none;
    border: none;
}

button:not(.icon-btn),
.btn:not(.icon-btn) {
    & > i {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        position: relative;
        top: -0.1rem;
    }
}

.mirror-icon {
    svg {
        transform: scaleX(-1);
    }
}

.control-wrap .suffix-content .icon-btn {
    margin: 0;
}
