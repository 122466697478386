@use "../../../../styles/scss/variables" as *;

.input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    flex: 0;
    flex-wrap: nowrap;

    .input-label {
        margin-right: 0.5rem;
        text-wrap: nowrap;
    }

    &.collapsed {
        border: 2px solid $app-border;
        border-radius: $border-radius;
        margin: 0.5rem 0.25rem;
        padding: 0.1rem 0.25rem;

        &.type__selection {
            flex-direction: column;
            div:last-child {
                white-space: nowrap;
            }
        }
    }

    &:not(.collapsed) {
        &.type__selection,
        &.type__string {
            > *:last-child {
                flex: 1;
            }
        }
    }

    .template-input.disabled {
        padding-top: 0;
        padding-bottom: 0;
    }
}
