@use '../../styles/scss/variables';

.side-nav {

  height: 100%;
  border-right: 1px solid variables.$app-border;
  position: fixed;
  top: 0;
  // min-width: $min-side-nav-width;

  .side-nav-outter {
    display: flex;
    flex-direction: column;
  }
}

button.btn.whats-new-btn {
  border-radius: 50rem;

  &:not(:hover) {
    background-color: transparentize($color: #fff, $amount: 0.2);
  }
}

.side-nav-wrapper {
  // display: flex;
}

.app-container {
  display: flex;
  flex: 1;

  &>div>
  main {
    padding: 0;
    // margin-left: max(divide(100%,6), $min-side-nav-width) ;
    max-height: 100vh;
    min-width: 0;
  }
}

.timeout-bar {
  margin: 0 3px;

  .label {
    font-size: 1.3rem;
    font-weight: 600;
    border-right: 6px solid;
    height: 30px;
    line-height: 30px;
  }
}

.progress-bar.timeout {
  @include variables.progress-bar-variant(variables.$danger);
}

.progress-bar.auto-save {
  @include variables.progress-bar-variant(darken($color: variables.$primary, $amount: 15));
}