// @forward "~bootstrap/scss/bootstrap" with (
//     $primary: #2694bf,
//     $secondary: #1f4ca6
// );
// @use "~@progress/kendo-theme-bootstrap/dist/all.scss" as kendo;
// @use '~bootstrap' as bs;

/* ### Main Colors ### */

/* Color Theme Swatches in Hex */
$dark: #112145;
$darkPrimary: #1f4ca6;
$primary: #2694bf;
$accent1: #2bb9d9;
$accent2: #20a1b6;

$text: $dark;
$light-gray: rgb(245, 245, 249);
$light-grey: $light-gray;

// $text: #1f2c3c;
$app-background: #fcfcfc;
$app-background-shadow: #f5f5f5;
$app-hover: #ebebeb;
$app-border: #dee2e6;

/* Color Theme Swatches in RGBA */
$REWIND-365-1-rgba: rgba(17, 33, 69, 1);
$REWIND-365-2-rgba: rgba(31, 76, 166, 1);
$REWIND-365-3-rgba: rgba(38, 148, 191, 1);
$REWIND-365-4-rgba: rgba(43, 185, 217, 1);
$REWIND-365-5-rgba: rgba(32, 161, 182, 1);

$theme-colors-map: (
    dark: $dark,
    darkPrimary: $darkPrimary,
    primary: $primary,
    accent1: $accent1,
    accent2: $accent2,
);

$border-radius: /*.25rem*/ .5rem !default;
$border-radius-lg: /*.3rem*/ .5rem !default;
$border-radius-sm: /*.2rem*/ .5rem !default;

// $darkPrimary: darken($color: $primary, $amount: 15);
// $darkPrimary: #024dbc;
// $primary: $darkPrimary;

// $is-dark-theme: true;

// $input-border-width: 2px !default;
// $input-border-height: ( 2px * 2 ) !default;
// $input-border-radius: $input-border-radius !default;
// $input-border-radius-sm: $input-border-radius-sm !default;
// $input-border-radius-lg: $input-border-radius-lg !default;

$input-calc-height: none;
$input-btn-padding-x: 0.5rem;
$input-btn-padding-y: 0.25rem;

// sizes
$min-side-nav-width: 0px;

// Additional Bootstrap styles

:root {
    --bs-primary: #{$darkPrimary};
}

// Breakpoints

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1520px,
);

// Telerik styles

// Colors

$kendo-color-primary: $primary;
$kendo-color-secondary: $accent1;
$kendo-color-tertiary: $accent2;

$percentiles: 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

@mixin highlight($color: #000) {
    font-weight: 700;
    color: $color;
}

@mixin rainbow-bg($duration) {
    animation: rainbow-bg $duration linear;
    animation-iteration-count: infinite;
}

// Striped progress bar styles

@mixin gradient-striped($color: rgba(255, 255, 255, 0.15), $angle: 45deg) {
    background-image: -webkit-linear-gradient(
        $angle,
        $color 25%,
        transparent 25%,
        transparent 50%,
        $color 50%,
        $color 75%,
        transparent 75%,
        transparent
    );
    background-image: -o-linear-gradient(
        $angle,
        $color 25%,
        transparent 25%,
        transparent 50%,
        $color 50%,
        $color 75%,
        transparent 75%,
        transparent
    );
    background-image: linear-gradient(
        $angle,
        $color 25%,
        transparent 25%,
        transparent 50%,
        $color 50%,
        $color 75%,
        transparent 75%,
        transparent
    );
}


@mixin animation($animation...) {
    -webkit-animation: $animation;
    -o-animation: $animation;
    animation: $animation;
}

@mixin progress-bar-variant($color) {
    background-color: lighten($color: $color, $amount: 38);

    .track {
        background-color: $color;
    }

    .label {
        padding-right: 1rem;
        padding-left: 5rem;
        border-color: $color;
        background: linear-gradient(
            90deg,
            transparent,
            transparentize($color: $color, $amount: 0.1)
        );
    }
}



/* Style overrides */

// $kendo-button-bg: kendo.$kendo-button-bg;

// $light: bs.$light;
// $gray-300: bs.$gray-300;
// $gray-200: bs.$gray-200;
// $gray-500: bs.$gray-500;
// $input-border-color: bs.$input-border-color;
// $input-padding-y: bs.$input-padding-y;
// $input-padding-x: bs.$input-padding-x;
// $red: bs.$red;
// $white: bs.$white;
// $yellow: bs.$yellow;
// $orange: bs.$orange;
// $blue: bs.$blue;
// $teal: bs.$teal;
// $green: bs.$green;
// $success: bs.$success;
// $danger: bs.$danger;
// $warning: bs.$warning;
// $spacer: bs.$spacer;


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~@progress/kendo-theme-bootstrap/scss/core";
@import "~@progress/kendo-theme-bootstrap/scss/button/variables";
// @import "~@progress/kendo-theme-bootstrap/scss/variables";
// @import "~@progress/kendo-theme-bootstrap/scss/typography/variables";
// @import "~@progress/kendo-theme-bootstrap/scss/color-system";
;@import "sass-embedded-legacy-load-done:0";