.copy-job-mappings-wizard {
    width: 80vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 2rem;

    &.inner {
        margin-top: 3rem;
        overflow-y: auto;

        .step {
            max-height: 100%;

            &.step-2 {
                display: flex;
                flex-direction: column;

                .data-view {                    
                    overflow-y: auto;
                }
            }
        }
    }
}
