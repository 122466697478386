@use '../../../styles/scss/variables';

.app-wizard{
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    .wizard-inner {
        height: 100%;
        overflow: auto;
    }

    .stepper-control {
        position: sticky;
        bottom: 0;
        padding-top: 1rem;
        padding-bottom: 2.5rem;
        background: variables.$app-background;
    }

}