@use "../../../styles/scss/variables";

.data-view-message {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        margin-top: 2rem;
        font-weight: 600;        
    }
}