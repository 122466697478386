@use "../../../styles/scss/variables";

.nav-item.nav-link {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border-left-color: variables.$white;

    flex: 1;
    &.collapsed-item {
        flex: 0;
    }

    &.top-level {
        margin: 0.5rem 0;
    }

    &:not(.top-level) {
        margin-left: 4.5rem;
        padding: 0.2rem 0.7rem 0.7rem 1rem;

        &:first-of-type {
            margin-top: 0.5rem;
        }

        &:not(:last-of-type) {
            margin-bottom: 0.5rem;
        }
    }

    .inner {
        display: flex;
        align-items: center;
    }

    * {
        color: variables.$text;
    }

    a {
        text-decoration: none;
    }

    .caption {
        position: relative;
        top: 0.25rem;
    }

    .expand-btn,
    .nav-icon {
        margin-right: 0.5rem;
        cursor: pointer;
    }

    &.selected > .inner a .caption {
        font-weight: bold;
    }

    &.collapsed-item .nav-icon {
        margin: 0;
    }

    &.active,
    &.selected.collapsed-item {
        background: linear-gradient(
            90deg,
            rgba(38, 141, 191, 0.15) 5%,
            rgba(38, 141, 191, 0.51)
        );        
    }

    &:hover {
        &:not(.top-level):not(.active) {
            background: linear-gradient(
                90deg,
                rgba(1, 141, 255, 0.07) 05%,
                rgba(10, 106, 185, 0.25)
            );
            // background: linear-gradient(90deg, rgba(1, 141, 255, 0.1), darken($color:rgba(1, 141, 255, 0.1), $amount:30));
        }
    }
}
