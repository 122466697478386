.icon-open-document, 
.icon-create-new, 
.icon-create-new-2, 
.icon-upload, 
.icon-appointment-reminders, 
.icon-download, 
.icon-add, 
.icon-vertical-settings-mixer, 
.icon-login-rounded, 
.icon-information, 
.icon-search-in-browser, 
.icon-hyperlink, 
.icon-browser-settings, 
.icon-intelligent-website, 
.icon-website-error, 
.icon-webkey, 
.icon-check-male, 
.icon-events, 
.icon-today, 
.icon-calendar, 
.icon-calendar-plus, 
.icon-calendar-error, 
.icon-calendar-list, 
.icon-clock-arrow, 
.icon-clock-add, 
.icon-remove-clock, 
.icon-clock, 
.icon-date-to, 
.icon-calendar-14, 
.icon-overtime, 
.icon-future, 
.icon-time-machine, 
.icon-important-file, 
.icon-linked-file, 
.icon-linechart-file, 
.icon-regular-file, 
.icon-add-file, 
.icon-check-file, 
.icon-delete-file, 
.icon-copy, 
.icon-file-preview, 
.icon-print-file, 
.icon-view-file, 
.icon-upload-document, 
.icon-receive-file, 
.icon-forward-all, 
.icon-reply-all-arrow, 
.icon-circular-arrows, 
.icon-expand-arrow, 
.icon-sorting-arrows-horizontal, 
.icon-hexagon-synchronize-, 
.icon-coin-wallet, 
.icon-bunch-of-keys-1, 
.icon-no-key, 
.icon-graph-report, 
.icon-error, 
.icon-agreement, 
.icon-urgent-message, 
.icon-smart-home-checked, 
.icon-cancel, 
.icon-puzzle, 
.icon-web-analystics, 
.icon-collapse-arrow, 
.icon-forward, 
.icon-stop, 
.icon-play, 
.icon-play-2, 
.icon-ball-point-pen, 
.icon-ball-point-pen-2, 
.icon-cloud-checked, 
.icon-unavailable-cloud, 
.icon-remote-working, 
.icon-error-cloud, 
.icon-upload-to-cloud, 
.icon-download-from-cloud, 
.icon-cloud-line-chart, 
.icon-cloud-clock, 
.icon-clock-checked, 
.icon-empty-box, 
.icon-filter, 
.icon-sorting-arrows, 
.icon-generic-sorting, 
.icon-sort-amount-up, 
.icon-sort-by-price, 
.icon-alphabetical-sorting, 
.icon-database-error, 
.icon-accept-database, 
.icon-add-database, 
.icon-data-encryption, 
.icon-remove-data, 
.icon-spiral-bound-booklet, 
.icon-print-message, 
.icon-checked, 
.icon-lock, 
.icon-trash, 
.icon-search, 
.icon-info, 
.icon-information-2, 
.icon-source-code, 
.icon-source-code-2, 
.icon-code, 
.icon-api, 
.icon-severity, 
.icon-test-partial-passed, 
.icon-comments, 
.icon-confetti, 
.icon-bullhorn-megaphone, 
.icon-like, 
.icon-hearts, 
.icon-hearts-2, 
.icon-file-contract, 
.icon-update-file, 
.icon-circled-right, 
.icon-circled-left, 
.icon-double-right, 
.icon-synchronize, 
.icon-synchronize-2, 
.icon-withdrawal, 
.icon-price-tag, 
.icon-average, 
.icon-gender-neutral-user, 
.icon-headset, 
.icon-clipboard, 
.icon-path-steps, 
.icon-planned-path, 
.icon-define-location, 
.icon-difficult-decision, 
.icon-deposit, 
.icon-heck-for-payment, 
.icon-expand-arrows, 
.icon-diagonal-arrows-right, 
.icon-stretch-diagonally, 
.icon-compress, 
.icon-fold-arrows, 
.icon-resize-file, 
.icon-collapse, 
.icon-expand, 
.icon-checklist, 
.icon-graph, 
.icon-regular-document, 
.icon-no-document, 
.icon-submit-document, 
.icon-mark-as-favorite, 
.icon-file-preview-2, 
.icon-view, 
.icon-document-splash, 
.icon-edit-file, 
.icon-route, 
.icon-marker, 
.icon-move-by-trolley-1, 
.icon-box, 
.icon-warehouse, 
.icon-package-delivery-logistics, 
.icon-add-to-collection, 
.icon-delivered-box, 
.icon-edit, 
.icon-edit-2, 
.icon-checked-2, 
.icon-cancel-2, 
.icon-circled-chevron-up, 
.icon-image-file, 
.icon-image-file-2, 
.icon-document, 
.icon-folder-invoices-3, 
.icon-folder-invoices-3-2, 
.icon-copy-2
 {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: -0.1em;
}

.icon-open-document>svg, 
.icon-create-new>svg, 
.icon-create-new-2>svg, 
.icon-upload>svg, 
.icon-appointment-reminders>svg, 
.icon-download>svg, 
.icon-add>svg, 
.icon-vertical-settings-mixer>svg, 
.icon-login-rounded>svg, 
.icon-information>svg, 
.icon-search-in-browser>svg, 
.icon-hyperlink>svg, 
.icon-browser-settings>svg, 
.icon-intelligent-website>svg, 
.icon-website-error>svg, 
.icon-webkey>svg, 
.icon-check-male>svg, 
.icon-events>svg, 
.icon-today>svg, 
.icon-calendar>svg, 
.icon-calendar-plus>svg, 
.icon-calendar-error>svg, 
.icon-calendar-list>svg, 
.icon-clock-arrow>svg, 
.icon-clock-add>svg, 
.icon-remove-clock>svg, 
.icon-clock>svg, 
.icon-date-to>svg, 
.icon-calendar-14>svg, 
.icon-overtime>svg, 
.icon-future>svg, 
.icon-time-machine>svg, 
.icon-important-file>svg, 
.icon-linked-file>svg, 
.icon-linechart-file>svg, 
.icon-regular-file>svg, 
.icon-add-file>svg, 
.icon-check-file>svg, 
.icon-delete-file>svg, 
.icon-copy>svg, 
.icon-file-preview>svg, 
.icon-print-file>svg, 
.icon-view-file>svg, 
.icon-upload-document>svg, 
.icon-receive-file>svg, 
.icon-forward-all>svg, 
.icon-reply-all-arrow>svg, 
.icon-circular-arrows>svg, 
.icon-expand-arrow>svg, 
.icon-sorting-arrows-horizontal>svg, 
.icon-hexagon-synchronize->svg, 
.icon-coin-wallet>svg, 
.icon-bunch-of-keys-1>svg, 
.icon-no-key>svg, 
.icon-graph-report>svg, 
.icon-error>svg, 
.icon-agreement>svg, 
.icon-urgent-message>svg, 
.icon-smart-home-checked>svg, 
.icon-cancel>svg, 
.icon-puzzle>svg, 
.icon-web-analystics>svg, 
.icon-collapse-arrow>svg, 
.icon-forward>svg, 
.icon-stop>svg, 
.icon-play>svg, 
.icon-play-2>svg, 
.icon-ball-point-pen>svg, 
.icon-ball-point-pen-2>svg, 
.icon-cloud-checked>svg, 
.icon-unavailable-cloud>svg, 
.icon-remote-working>svg, 
.icon-error-cloud>svg, 
.icon-upload-to-cloud>svg, 
.icon-download-from-cloud>svg, 
.icon-cloud-line-chart>svg, 
.icon-cloud-clock>svg, 
.icon-clock-checked>svg, 
.icon-empty-box>svg, 
.icon-filter>svg, 
.icon-sorting-arrows>svg, 
.icon-generic-sorting>svg, 
.icon-sort-amount-up>svg, 
.icon-sort-by-price>svg, 
.icon-alphabetical-sorting>svg, 
.icon-database-error>svg, 
.icon-accept-database>svg, 
.icon-add-database>svg, 
.icon-data-encryption>svg, 
.icon-remove-data>svg, 
.icon-spiral-bound-booklet>svg, 
.icon-print-message>svg, 
.icon-checked>svg, 
.icon-lock>svg, 
.icon-trash>svg, 
.icon-search>svg, 
.icon-info>svg, 
.icon-information-2>svg, 
.icon-source-code>svg, 
.icon-source-code-2>svg, 
.icon-code>svg, 
.icon-api>svg, 
.icon-severity>svg, 
.icon-test-partial-passed>svg, 
.icon-comments>svg, 
.icon-confetti>svg, 
.icon-bullhorn-megaphone>svg, 
.icon-like>svg, 
.icon-hearts>svg, 
.icon-hearts-2>svg, 
.icon-file-contract>svg, 
.icon-update-file>svg, 
.icon-circled-right>svg, 
.icon-circled-left>svg, 
.icon-double-right>svg, 
.icon-synchronize>svg, 
.icon-synchronize-2>svg, 
.icon-withdrawal>svg, 
.icon-price-tag>svg, 
.icon-average>svg, 
.icon-gender-neutral-user>svg, 
.icon-headset>svg, 
.icon-clipboard>svg, 
.icon-path-steps>svg, 
.icon-planned-path>svg, 
.icon-define-location>svg, 
.icon-difficult-decision>svg, 
.icon-deposit>svg, 
.icon-heck-for-payment>svg, 
.icon-expand-arrows>svg, 
.icon-diagonal-arrows-right>svg, 
.icon-stretch-diagonally>svg, 
.icon-compress>svg, 
.icon-fold-arrows>svg, 
.icon-resize-file>svg, 
.icon-collapse>svg, 
.icon-expand>svg, 
.icon-checklist>svg, 
.icon-graph>svg, 
.icon-regular-document>svg, 
.icon-no-document>svg, 
.icon-submit-document>svg, 
.icon-mark-as-favorite>svg, 
.icon-file-preview-2>svg, 
.icon-view>svg, 
.icon-document-splash>svg, 
.icon-edit-file>svg, 
.icon-route>svg, 
.icon-marker>svg, 
.icon-move-by-trolley-1>svg, 
.icon-box>svg, 
.icon-warehouse>svg, 
.icon-package-delivery-logistics>svg, 
.icon-add-to-collection>svg, 
.icon-delivered-box>svg, 
.icon-edit>svg, 
.icon-edit-2>svg, 
.icon-checked-2>svg, 
.icon-cancel-2>svg, 
.icon-circled-chevron-up>svg, 
.icon-image-file>svg, 
.icon-image-file-2>svg, 
.icon-document>svg, 
.icon-folder-invoices-3>svg, 
.icon-folder-invoices-3-2>svg, 
.icon-copy-2>svg
 {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: -0.1em;
}

