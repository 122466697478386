.dev-ops-administration, .copy-job-mappings-wizard  {
    .dev-ops-mapping {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left {
            display: flex;
            column-gap: 2rem;
        }
    }
}
