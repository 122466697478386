@use "../../../styles/scss/variables" as *;

.container {
    border: 1px solid $app-border;
    border-radius: $border-radius;
    margin: 1rem;
    flex: 0;

    &.labelled-container {
        margin-top: 2rem;

        .container-label {
            font-weight: 600;
            text-wrap: nowrap;
        }

        &:not(.collapsed) {
            flex: 1;
            .container-label {
                position: relative;
                top: -1rem;
                background-color: $app-background;
                padding: 0 0.5rem;
            }
        }

        &.collapsed {
            margin: 0.5rem;
        }
    }

    &.collapsed {
        display: flex;
        align-items: center;
        flex: 1;
        flex-wrap: wrap;    
    }
}
