.dialog-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .dialog-header {
        .dialog-filters {
            display: flex;
            gap: 1rem;
        }

        .k-breadcrumb {
            #root {
                min-height: 2rem;
            }
        }
    }

    .dialog-data {
        margin-top: 2rem;
        flex: 1;
        min-height: 0;        
    }

    .preview-pane {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-left: 1px solid rgba(0, 0, 0, 0.08);
        background-color: #f9f9f9;
        padding: 1rem;

        .preview-pane-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);

            h3 {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .preview-pane-content {
            flex: 1;
            overflow: auto;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .preview-pane--empty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: rgba(0, 0, 0, 0.5);
            font-style: italic;
        }
    }
}
